
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonRadio,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { useStore } from "../store";

declare global {
  interface Window {
    paypal: any;
  }
}

export default defineComponent({
  name: "Checkout",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonRadio,
    Header,
    Footer,
  },
  data: () => {
    return {
      store: useStore(),
      showAddress: true,
      addressSuccess: false,
      showPayments: false,
      addressLoading: false,
      paymentLoading: false,
      paypalLoaded: false,
      paymentSuccess: false,
      paymentMethod: "",
      errors: {
        address: [] as any,
        payments: [] as any,
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DDTHH:mm:ssZZZZ",
      },
      address: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        parish: "",
        postcode: "",
      },
    };
  },
  computed: {
    getPartyBag(): any {
      return this.store.getters.getPartyBag();
    },
  },
  methods: {
    selectPaymentMethod(e: Event, value: string) {
      if (value === "paypal") {
        this.paymentMethod = value;
        if (this.paypalLoaded === false) {
          this.loadPaypalButtons();
        }
      }
      if (value === "citypay") {
        this.paymentMethod = value;
      }
    },
    async confirmAddress() {
      this.errors.address = [];
      if (
        this.address.firstName === "" ||
        this.address.addressLine1 === "" ||
        this.address.parish === "" ||
        this.address.postcode === ""
      ) {
        this.errors.address.push("Please fill out the required fields");
      } else {
        // Send address to API
        try {
          this.addressLoading = true;
          const res = await this.store.dispatch("sendAddressForPartyPack", {
            address: this.address,
            partyIdentifier: this.$route.params.identifier,
          });
          if (res === 200) {
            this.addressSuccess = true;
            this.showPayments = true;
          }
          this.addressLoading = false;
        } catch (e) {
          console.log(e.response);
        }
      }
    },
    async confirmPayment() {
      if (this.store.state.user === null) {
        this.$router.push({ path: "/register" });
      }
      this.paymentLoading = true;
      this.errors.payments = [];
      try {
        // Confirm Payments etc...
        // Get Token
        const partyId = (this.$route.params.identifier as string)
          .split("-")
          .slice(-1)[0];
        const res = await this.store.dispatch("getPaylinkToken", partyId);
        // Redirect to payment
        // console.log("paylink res");
        // console.log(res);
        if (res.data.result === 1) {
          window.location.href = res.data.url;
        }
        // Caputure details on success or failure page
        // Maybe Merge the two and make dynamic
      } catch (e) {
        console.log("catch error on confirm payment");
        console.log(e.response);
      }
      this.paymentLoading = false;
    },
    loadPaypalButtons() {
      this.paypalLoaded = true;
      const self = this;
      window.paypal
        .Buttons({
          createOrder: (data: any, actions: any) => {
            return actions.order.create({
              // eslint-disable-next-line
              purchase_units: [
                {
                  // eslint-disable-next-line
                  custom_id: this.$route.params.identifier,
                  description: "Party Plan + Party Pack",
                  amount: {
                    // eslint-disable-next-line
                    currency_code: "GBP",
                    value: "4.00",
                  },
                },
              ],
            });
          },
          onApprove: function (data: any, actions: any) {
            return actions.order.capture().then(function (orderData: any) {
              // Successful capture! For dev/demo purposes:
              // console.log(
              //   "Capture result",
              //   orderData,
              //   JSON.stringify(orderData, null, 2)
              // );

              const identifier = orderData.purchase_units[0].custom_id;
              const status =
                orderData.purchase_units[0].payments.captures[0].status;
              const id = orderData.purchase_units[0].payments.captures[0].id;
              // eslint-disable-next-line
              self.confirmPaypal(identifier, status, id);
            });
          },
          onError: (err: any) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },
    async confirmPaypal(
      identifier: string,
      status: string,
      transactionId: string
    ) {
      // Send address to API
      try {
        this.paymentLoading = true;
        const res = await this.store.dispatch("sendPaypalResponse", {
          identifier,
          status,
          transactionId,
        });
        console.log("res paypal");
        console.log(res);
        if (res.status === 200) {
          this.paymentSuccess = true;
          if (res.data.status === "COMPLETED") {
            this.$router.push({ path: "/success/" + res.data.identifier });
          }
        }
        this.paymentLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
});
